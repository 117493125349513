import React from "react";
import { graphql, Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import { IoMdOpen, IoIosCall, IoIosMail, IoIosPin } from "react-icons/io";
import styled from "styled-components";

import Seo from "../components/seo";
import { Container, Wrapper, Row, Box } from "../components/util";
import { Breadcrumb } from "../components/site";
import Layout from "../components/layout";

// import PayrollCertifiedLogo from "../images/logos/PayHero/PayHero_PayrollCertified.svg";
import PayHeroSingleLogo from "../images/logos/PayHero/PayHero_Single.svg";
import DroppahSingleLogo from "../images/logos/Droppah/Droppah_Single.svg";
import InvoxySingleLogo from "../images/logos/Invoxy/Invoxy_Single.svg";
import GoldBadge from "../images/logos/FlexiTime/Partners/FlexiTime_Gold_Badge.svg";
import SilverBadge from "../images/logos/FlexiTime/Partners/FlexiTime_Silver_Badge.svg";
import BronzeBadge from "../images/logos/FlexiTime/Partners/FlexiTime_Bronze_Badge.svg";

const PartnerLogo = styled.div`
  border-radius: 1000px;
  background-color: ${(props) => props.theme.colours.white};
  box-shadow: ${(props) => props.theme.styles.shadow};
  /* border: solid 1px #ddd; */
  width: 100%;
  padding-top: 100%;
  position: relative;
  padding-bottom: 0;

  > div {
    position: absolute !important;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 85%;
  }
`;

export const query = graphql`
  query($slug: String!) {
    contentfulPartner(landingPageUrl: { eq: $slug }) {
      logo {
        file {
          url
        }
        gatsbyImageData(layout: FULL_WIDTH, quality: 100)
      }
      name
      contactName
      contactNumber
      email
      partnerWebsite
      address
      serviceLocations
      payHeroCertified
      droppahCertified
      invoxyCertified
      partnerStatusLevel
      region
      description {
        raw
      }
    }
  }
`;

const Partner = (props) => {
  return (
    <Layout>
      <Seo
        title={`${props.data.contentfulPartner.name} | ${props.data.contentfulPartner.payHeroCertified
            ? "Payroll Certified | Partner"
            : "Partner"
          }`}
        description={
          "Check out " +
          props.data.contentfulPartner.name +
          ", one of our amazing Partners. For payroll help visit their website, give them a call or send them an email and they'd be happy to help!"
        }
        image={
          props.data.contentfulPartner.logo
            ? "https:" + props.data.contentfulPartner.logo.file.url
            : ""
        }
        pathname={props.location.pathname}
      />
      {props.data.contentfulPartner.partnerStatusLevel === "Gold" ? (
        <>
          <Breadcrumb
            parentPage="Partner Directory"
            parentPageLink="/partner-directory"
            currentPage={props.data.contentfulPartner.name}
          />
          <Container bg="#E1CC0A">
            <Wrapper stackGap={2}></Wrapper>
          </Container>
          <Container>
            <Wrapper maxWidth={1200} stackGap={80}>
              <Row stackGap={80}>
                <Box size={25} stackGap={50} centerOnMobile>
                  <PartnerLogo>
                    <GatsbyImage
                      className="-center"
                      image={props.data.contentfulPartner.logo.gatsbyImageData}
                      alt={
                        props.data.contentfulPartner.name +
                        "| FlexiTime Partner"
                      }
                    />
                  </PartnerLogo>
                </Box>
                <Box size={75} stackGap={50}>
                  <Box stackGap={20}>
                    <Row justify="flex-start" stackGap={15} alignCenter noBreak>
                      <img
                        src={
                          props.data.contentfulPartner.partnerStatusLevel ===
                            "Gold"
                            ? GoldBadge
                            : props.data.contentfulPartner
                              .partnerStatusLevel === "Silver"
                              ? SilverBadge
                              : BronzeBadge
                        }
                        alt={`FlexiTime ${props.data.contentfulPartner.partnerStatusLevel} Partner`}
                        height="25"
                      />
                      {props.data.contentfulPartner.payHeroCertified && (
                        <>
                          <div>·</div>
                          <Row
                            justify="flex-start"
                            alignCenter
                            stackGap={10}
                            css={{ width: "auto" }}
                            noBreak
                          >
                            <img
                              src={PayHeroSingleLogo}
                              alt="PayHero | Payroll Certified Partner"
                              height="20"
                            />
                            <p
                              className="-fontBold hideOnMobile"
                              css={{ marginBottom: "0" }}
                            >
                              Payroll Certified
                            </p>
                          </Row>
                        </>
                      )}
                      {props.data.contentfulPartner.droppahCertified && (
                        <>
                          <div>·</div>
                          <Row
                            justify="flex-start"
                            alignCenter
                            stackGap={10}
                            css={{ width: "auto" }}
                            noBreak
                          >
                            <img
                              src={DroppahSingleLogo}
                              alt="Droppah | Rostering Certified Partner"
                              height="20"
                            />
                            <p
                              className="-fontBold hideOnMobile"
                              css={{ marginBottom: "0" }}
                            >
                              Rostering Certified
                            </p>
                          </Row>
                        </>
                      )}
                      {props.data.contentfulPartner.invoxyCertified && (
                        <>
                          <div>·</div>
                          <Row
                            justify="flex-start"
                            alignCenter
                            stackGap={10}
                            css={{ width: "auto" }}
                            noBreak
                          >
                            <img
                              src={InvoxySingleLogo}
                              alt="Karmly | Contractor Management Certified Partner"
                              height="20"
                            />
                            <p
                              className="-fontBold hideOnMobile"
                              css={{ marginBottom: "0" }}
                            >
                              Contractor Management Certified
                            </p>
                          </Row>
                        </>
                      )}
                    </Row>
                    <h1 className="-fontDark">
                      {props.data.contentfulPartner.name}
                    </h1>
                    {props.data.contentfulPartner.description && (
                      <div>
                        {renderRichText(
                          props.data.contentfulPartner.description
                        )}
                      </div>
                    )}
                  </Box>
                  <Box stackGap={25}>
                    <h3 css={{ color: "#333" }}>
                      <strong>Details</strong>
                    </h3>
                    {props.data.contentfulPartner.contactName && (
                      <h3 css={{ color: "#333" }}>
                        <strong>
                          {props.data.contentfulPartner.contactName}
                        </strong>
                      </h3>
                    )}
                    {props.data.contentfulPartner.region && (
                      <div
                        css={{
                          display: "flex",
                          color: "#999",
                          fontSize: "1.3rem",
                        }}
                        className="-textCenter"
                      >
                        <IoIosPin
                          css={{
                            color: "#999",
                            marginRight: "5px",
                            fontSize: "20px",
                          }}
                        />
                        <div>{props.data.contentfulPartner.region}</div>
                      </div>
                    )}
                    {props.data.contentfulPartner.email && (
                      <h4>
                        <a
                          className="link-floating"
                          href={`mailto:${props.data.contentfulPartner.email}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <IoIosMail css={{ top: "3px", marginRight: "7px" }} />{" "}
                          {props.data.contentfulPartner.email}
                        </a>
                      </h4>
                    )}
                    {props.data.contentfulPartner.contactNumber && (
                      <h4>
                        <a
                          className="link-floating"
                          href={`tel:${props.data.contentfulPartner.contactNumber}`}
                        >
                          <IoIosCall css={{ top: "3px", marginRight: "7px" }} />{" "}
                          {props.data.contentfulPartner.contactNumber}
                        </a>
                      </h4>
                    )}
                    {props.data.contentfulPartner.partnerWebsite && (
                      <h4>
                        <a
                          className="link-floating"
                          href={props.data.contentfulPartner.partnerWebsite}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Visit Website{" "}
                          <IoMdOpen css={{ top: "3px", left: "3px" }} />
                        </a>
                      </h4>
                    )}
                  </Box>
                </Box>
              </Row>
            </Wrapper>
          </Container>
          <Container className="secondary -textCenter">
            <Wrapper stackGap={30}>
              <h4 className="-fontNormal">
                Find out more about{" "}
                <Link to="/become-a-partner">FlexiTime's partner program</Link>.
              </h4>
            </Wrapper>
          </Container>
        </>
      ) : (
        <Container bg="lightGrey">
          <Wrapper>
            <Box className="-center" css={{ maxWidth: "600px" }}>
              <h4 className="-textCenter -fontNormal">
                Looking for a FlexiTime partner? Check out our{" "}
                <Link to="/partner-directory">partner directory</Link> or find
                out more about{" "}
                <Link to="/become-a-partner">FlexiTime's partner program</Link>.
              </h4>
            </Box>
          </Wrapper>
        </Container>
      )}
    </Layout>
  );
};

export default Partner;
